<template>
  <div class="mine">
    <el-table stripe :data="tableData" :key="itemKey">
      <el-table-column
        show-overflow-tooltip
        prop="title"
        label="标题"
        width="220"
      >
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top" width="220">
            <p>{{ scope.row.text }}</p>
            <div
              slot="reference"
              class="name-wrapper"
              style="white-space: pre-wrap"
            >
              {{ scope.row.title }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="workspaceStr" label="建议事项" width="120">
      </el-table-column>

      <el-table-column prop="categoryStr" label="分类" width="120">
      </el-table-column>
      <el-table-column prop="subcategoryStr" label="子类" width="120">
      </el-table-column>
      <el-table-column prop="status" label="状态" width="120">
        <template slot-scope="scope">
          <el-tag size="medium" :type="scope.row.statusType">{{
            scope.row.statusStr
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="modified" label="更新日期" width="220">
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            v-if="canEdit(scope.row)"
            type="text"
            size="mini"
            @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            v-if="canEdit(scope.row)"
            type="text"
            size="mini"
            @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row style="margin-top: 12px">
      <el-col style="text-align: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>确认要删除这个需求吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteStory">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, delete as del } from 'axios'
export default {
  data() {
    return {
      total: 0,
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      dialogVisible: false,
      rowSelected: {},
      user: window.globalData?.user || {},
      categoryList: window.globalData?.config.categoryList || [],
      threeClassDic: window.globalData?.config.threeClassDic || [],
      tagList: window.globalData?.config.tagList || [],
      statusList: window.globalData?.config.statusList || [],
      storyData: [],
      tableData: [],
      globalData: {},
      itemKey: Math.random(),
    }
  },
  methods: {
    async deleteStory() {
      console.log('Delete story: ')
      console.log(this.rowSelected)
      if (this.rowSelected.id) {
        let { data } = await del(
          'api/opinions/delete?id=' + this.rowSelected.id
        )
        console.log('result of delte story: ' + data)
        this.refreshStoryList()
      }
      this.dialogVisible = false
    },
    //调整当前的页码
    handleCurrentChange(pageNumber) {
      //数据重新分页
      this.getPageInfo({ page: pageNumber })
    },
    //分页时修改每页的行数,这里会自动传入一个size
    handleSizeChange(size) {
      //修改当前每页的数据行数
      this.queryParams.pageSize = size
      //数据重新分页
      this.getPageInfo({ page: 1 })
    },
    async getPageInfo({ page = 1 }) {
      this.queryParams = { ...this.queryParams, page }

      let { data } = await get(
        `api/opinions/list?creator=${this.user.userid}&page=${this.queryParams.page}&pageSize=${this.queryParams.pageSize}`
      )
      if (data.code === 0) {
        let { records = [], total = 0 } = data.data
        this.storyData = records
        this.total = total

        this.tableData = this.storyData.map((item) => {
          let obj = {
            ...item,
          }
          //建议事项
          let worekSpaceIndex = this.categoryList.findIndex(
            (o) => item.workspaceId == o.workspace_id
          )
          obj.workspaceStr =
            worekSpaceIndex != -1 ? this.categoryList[worekSpaceIndex].name : ''
          if (worekSpaceIndex != -1) {
            //分类
            let categoryIndex = this.categoryList[
              worekSpaceIndex
            ].secondList.findIndex((o) => o.id == obj.category)
            let categoryStr =
              categoryIndex != -1
                ? this.categoryList[worekSpaceIndex].secondList[categoryIndex]
                    .name
                : ''
            obj.categoryStr = categoryStr
          } else {
            obj.categoryStr = ''
          }
          //需求类型
          let subcategoryIndex = this.threeClassDic.findIndex(
            (o) => o.id == item.type
          )
          obj.subcategoryStr =
            subcategoryIndex != -1
              ? this.threeClassDic[subcategoryIndex].name
              : ''

          //任务状态
          let statusIndex = this.statusList.findIndex(
            (o) => o.value == item.status
          )
          obj.statusStr =
            statusIndex != -1 ? this.statusList[statusIndex].label : ''
          obj.statusType = obj.statusStr
            ? this.tagList.find((o) => o.value == obj.statusStr).label
            : ''
          return obj
        })
        this.itemKey = Math.random()
      } else {
        this.$message.error('读取列表失败!')
      }
    },
    async refreshStoryList() {
      this.tableData.length = 0
      this.getPageInfo({ page: 1 })
    },
    canEdit(row) {
      console.log('Story status: ' + row.status)
      if (row.status !== '实现中' && row.status !== '已实现') {
        return true
      } else {
        return false
      }
    },
    handleEdit(row) {
      console.log('handleEdit: ' + row.id)
      this.rowSelected = row
      console.log(this.rowSelected)

      this.$bus.emit('edit', row.id)
    },
    handleDelete(row) {
      console.log('handleDelete: ')
      this.rowSelected = row
      console.log(this.rowSelected)
      this.dialogVisible = true
    },
  },
  mounted() {
    this.globalData = window.globalData

    console.log('user: ' + this.user)

    if (!this.user.userid) {
      console.log('userid not found!')
      return
    }

    this.refreshStoryList()
  },
}
</script>

<style>
.mine {
  padding: 40px;
  width: 1040px;
}
</style>
