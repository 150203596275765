<template>
  <div class="story">
    <el-alert
      style="margin-left: 80px; margin-bottom: 10px; width: 560px"
      title="如您有多个建议或问题，请分别单独提交，便于管理和反馈。"
      type="warning"
    >
    </el-alert>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :disabled="!isLogin"
      label-width="80px"
      style="width: 640px"
    >
      <el-form-item label="建议事项" prop="department_id">
        <el-select
          v-model="form.department_id"
          placeholder="请选择建议事项"
          style="width: 100%"
        >
          <el-option
            v-for="item in departmentDic"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="分类" prop="category">
        <el-select
          v-model="form.category"
          placeholder="选择业务系统，如果不确定请选‘其他’"
          style="width: 100%"
        >
          <el-option
            v-for="item in categoryDic"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="子类" prop="subcategory">
        <el-select
          v-model="form.subcategory"
          :disabled="form.department_id && subcategoryDic.length == 0"
          @change="subcategoryChange"
          :placeholder="
            form.department_id && subcategoryDic.length == 0
              ? ''
              : '请选择子类，如果不确定请选‘其他'
          "
          style="width: 100%"
        >
          <el-option
            v-for="item in subcategoryDic"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="标题" prop="title">
        <el-input
          placeholder="简单概括您的建议(不超过30个字)"
          maxlength="30"
          show-word-limit
          v-model="form.title"
        ></el-input>
      </el-form-item>

      <el-form-item label="描述" prop="text">
        <el-input
          type="textarea"
          :autosize="{ minRows: 10, maxRows: 15 }"
          maxlength="2000"
          show-word-limit
          placeholder="详细说说您的想法或者遇到的问题(2000个字以内)"
          v-model="form.text"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="上传图片">
        <el-upload
          :class="{
            styleShow: form.piclist.length === 0,
            styleHide: form.piclist.length === maxPics,
          }"
          list-type="picture-card"
          :multiple="true"
          :limit="this.maxPics"
          :auto-upload="true"
          :file-list="fileList"
          action="api/common/upload"
          :before-upload="beforeUpload"
          :on-success="eventUploadSuccess"
          :on-remove="eventRemove"
          :on-exceed="eventExceed"
          name="file"
        >
          <i slot="default" class="el-icon-plus"></i>
          <div class="el-upload__tip" slot="tip">
            最多上传 {{ maxPics }} 张图片，支持JPG、PNG格式
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="上传文件">
        <el-upload
          :class="{
            styleShow: form.filelist.length === 0,
            styleHide: form.filelist.length === maxPics,
          }"
          :multiple="true"
          :limit="this.maxPics"
          :auto-upload="true"
          :file-list="fileList2"
          action="api/common/upload"
          :on-success="eventUploadSuccessFile"
          :on-remove="eventRemoveFile"
          :on-exceed="eventExceedFile"
          name="file"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div class="el-upload__tip" slot="tip">
            最多上传 {{ maxPics }} 个文件
          </div>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          style="margin-left: 140px; width: 200px"
          :loading="showLoading"
          @click="onSubmit"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
    <el-dialog
      title="感谢您的支持"
      :visible.sync="messageDialogVisible"
      width="40%"
      center
    >
      <span
        >您的建议/需求已收到，因第三方系统问题，数据同步会有延迟，可能稍后才能在”我的“列表中看到，无需重新提交，感谢您的支持！</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put } from 'axios'
export default {
  data() {
    return {
      fileList: [], //上传图片
      fileList2: [], //上传文件
      form: {
        id: '',
        department_id: '', //部门 id
        department: '', //需求创建人所属部门（隐形字段）
        subcategory: '', //需求类型 三级分类
        category: '', //分类  二级分类
        title: '',
        text: '',
        content: '',
        status: '',
        creator: {
          id: window.globalData?.user.userid || {},
          name: window.globalData?.user.name || {},
        },
        filelist: [],
        piclist: [],
      },
      rules: {
        category: [
          {
            required: true,
            message: '选择业务系统，如果不确定请选‘其他’',
            trigger: 'change',
          },
        ],
        department_id: [
          {
            required: true,
            message: '请选择部门',
            trigger: 'change',
          },
        ],
        title: [{ required: true, message: '请用一句话概括', trigger: 'blur' }],
        text: [
          {
            required: true,
            message: '请详细描述您的需求或建议',
            trigger: 'blur',
          },
        ],
      },
      threeClassDic: window.globalData?.config.threeClassDic || [],
      categoryList: window.globalData?.config.categoryList || [],
      isLogin: window.globalData?.user.userid !== undefined,
      /* 
      categoryList: [
        {
          name: '大数据中心',
          workspace_id: '55460314',
          url: 'https://3167989-1313556180.ipaas.sandbox.myqcloud.com/f85d',
          secondList: [
            {
              name: '美保助手（PC版）',
              id: '1155460314001000031',
            },
            {
              name: '美保助手（手机版）',
              id: '1155460314001000030',
            },
            {
              name: '统一理赔',
              id: '1155460314001000016',
            },
            {
              name: '分销系统',
              id: '1155460314001000017',
            },
            {
              name: '美保安全员',
              id: '1155460314001000018',
            },
            {
              name: '特种作业证SaaS',
              id: '1155460314001000021',
            },
            {
              name: '运营后台',
              id: '1155460314001000019',
            },
            {
              name: '产品中心',
              id: '1155460314001000014',
            },
            {
              name: '风控系统',
              id: '1155460314001000013',
            },
            {
              name: '合作方管理',
              id: '1155460314001000022',
            },
            {
              name: '应收系统',
              id: '1155460314001000020',
            },
            {
              name: '新市民保',
              id: '1155460314001000023',
            },
            {
              name: '查勘系统',
              id: '1155460314001000024',
            },
            {
              name: '数据平台',
              id: '1155460314001000032',
            },
            {
              name: '视觉设计',
              id: '1155460314001000055',
            },
            {
              name: '其他',
              id: '1155460314001000012',
            },
          ],
        },
        {
          name: '产品中心',
          workspace_id: '34875627',
          url: 'https://3167994-1313556180.ipaas.sandbox.myqcloud.com/5c6d',
          secondList: [
            {
              name: '家电',
              id: '1134875627001000074',
            },
            {
              name: '泛高空',
              id: '1134875627001000075',
            },
            {
              name: '团意险',
              id: '1134875627001000076',
            },
            {
              name: '安责险',
              id: '1134875627001000077',
            },
            {
              name: '新市民保',
              id: '1134875627001000078',
            },
            {
              name: '光伏',
              id: '1134875627001000079',
            },
            {
              name: '电梯',
              id: '1134875627001000080',
            },
            {
              name: '其他产品',
              id: '1134875627001000081',
            },
          ],
        },
        {
          name: '运营中心',
          workspace_id: '57424237',
          url: 'https://3167991-1313556180.ipaas.sandbox.myqcloud.com/7ec9',
          secondList: [
            {
              name: '理赔',
              id: '1157424237001000063',
            },
            {
              name: '客服',
              id: '1157424237001000064',
            },
          ],
        },
        {
          name: '人数中心-数据组',
          workspace_id: '58498555',
          url: 'https://3167996-1313556180.ipaas.sandbox.myqcloud.com/ed9c',
          secondList: [
            {
              name: '数据看板',
              id: '1158498555001000094',
            },
            {
              name: '数据大屏',
              id: '1158498555001000095',
            },
            {
              name: '统计分析',
              id: '1158498555001000096',
            },
            {
              name: '其他',
              id: '1158498555001000097',
            },
          ],
        },
        {
          name: '人数中心-人力行政组',
          workspace_id: '34281411',
          url: 'https://3168001-1313556180.ipaas.sandbox.myqcloud.com/c7f8',
          secondList: [
            {
              name: '行政',
              id: '1134281411001000098',
            },
            {
              name: '人力',
              id: '1134281411001000099',
            },
            {
              name: '薪资',
              id: '1134281411001000100',
            },
            {
              name: '员工关怀',
              id: '1134281411001000101',
            },
            {
              name: '其他建议',
              id: '1134281411001000102',
            },
          ],
        },
        {
          name: '财务管理部',
          workspace_id: '68462762',
          url: 'https://3168005-1313556180.ipaas.sandbox.myqcloud.com/e5f0',
          secondList: [
            {
              name: '报销',
              id: '1168462762001000087',
            },
            {
              name: '应收',
              id: '1168462762001000088',
            },
          ],
        },
      ],
      threeClassDic: [
        {
          workspace_id: '34875627',
          name: '承保',
          id: '1',
        },
        {
          workspace_id: '34875627',
          name: '产品',
          id: '2',
        },
        {
          workspace_id: '34875627',
          name: '其他',
          id: '3',
        },
        {
          workspace_id: '57424237',
          name: '家电',
          id: '4',
        },
        {
          workspace_id: '57424237',
          name: '泛高空',
          id: '5',
        },
        {
          workspace_id: '57424237',
          name: '新市民保',
          id: '6',
        },
        {
          workspace_id: '57424237',
          name: '其他',
          id: '7',
        },
      ],
      isLogin: true,
      */
      showLoading: false,
      messageDialogVisible: false,
      maxPics: 9,
      updateStory: false,
    }
  },
  computed: {
    //部门字典项
    departmentDic() {
      if (this.categoryList && this.categoryList.length > 0) {
        return this.categoryList.map(
          ({ name = '', workspace_id = '', url = '', secondList = [] }) => ({
            label: name,
            value: workspace_id,
            webhook: url,
            secondList,
          })
        )
      }
      return []
    },
    //分类
    categoryDic() {
      if (
        this.categoryList &&
        this.categoryList.length > 0 &&
        this.form.department_id
      ) {
        let findIndex = this.departmentDic.findIndex(
          (item) => item.value == this.form.department_id
        )
        if (findIndex == -1) {
          return []
        }
        return this.departmentDic[findIndex].secondList.map(
          ({ name = '', id = '' }) => ({
            label: name,
            value: id,
          })
        )
      }
      return []
    },
    //需求类型字典项目
    subcategoryDic() {
      if (
        this.categoryList &&
        this.categoryList.length > 0 &&
        this.form.department_id
      ) {
        return this.threeClassDic
          .filter((item) => item.workspace_id == this.form.department_id)
          .map(({ name = '', id = '' }) => ({
            label: name,
            value: id,
          }))
      }
      return []
    },
  },

  watch: {
    'form.department_id': {
      handler(newval) {
        //重新选择部门需要清空二级分类以及子类
        if (newval) {
          this.form.category = ''
          this.form.subcategory = ''
        }
      },
    },
  },
  methods: {
    closeDialog() {
      this.messageDialogVisible = false
      this.$router.push({ name: 'mine' })
    },
    //格式化描述，将上传文件以及上传图片在描述中删除
    removeFromBrToEnd(str) {
      // 正则表达式匹配从第一个<br />（允许空格）开始到字符串末尾的所有内容
      // 注意：这里假设<br />后不会紧跟在另一个标签之前（如<br /><img>），
      // 如果有这种情况，你可能需要调整正则表达式以更精确地匹配。
      const regex = /<br\s*\/?>.*$/

      // 使用replace方法删除匹配到的内容
      // 注意：replace方法的第二个参数为空字符串，表示删除匹配到的内容
      return str.replace(regex, '')
    },

    subcategoryChange(val) {
      //需求类型选中不触发 视图变更，这里强行刷新
      this.form = { ...this.form, subcategory: val }
    },
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.showLoading = true
          //需要存储创建人所在部门名称和所在部门 id
          let submitForm = {
            ...this.form,
            department_id: window.globalData?.user.departmentId,
            department: window.globalData?.user.departmentName,
          }
          submitForm.content = submitForm.text

          for (let i = 0; i < submitForm.piclist.length; i++) {
            submitForm.content =
              submitForm.content +
              '<br>' +
              '<img src="' +
              submitForm.piclist[i] +
              '">'
          }
          if (submitForm.piclist) {
            submitForm.piclist = submitForm.piclist.join('|')
          }

          for (let i = 0; i < this.form.filelist.length; i++) {
            submitForm.content =
              submitForm.content +
              '<br>' +
              '<a href="' +
              submitForm.filelist[i] +
              '">' +
              this.form.filelist[i] +
              '</a>'
          }
          if (submitForm.filelist) {
            submitForm.filelist = submitForm.filelist.join('|')
          }
          console.log('submitForm', submitForm)
          //获取部门下面对应的 hook 地址
          const webhook =
            this.categoryList[
              this.categoryList.findIndex(
                (o) => o.workspace_id == this.form.department_id
              )
            ].url
          try {
            let resp = await post('api/robot/send', {
              form: submitForm,
              webhook,
            })
            let {
              data: { status, errorMsg },
            } = resp
            console.log(resp, status)

            if (resp.status == '200') {
              if (this.updateStory) {
                let response2 = await put(
                  'api/opinions/put?id=' + this.form.id,
                  {
                    form: submitForm,
                  }
                )
                let { data } = response2
                console.log(data)
                if (response2.status == '200') {
                  this.messageDialogVisible = true
                  this.clearFormData()
                } else {
                  this.$message.error('发送失败: ' + errorMsg || '')
                }
              } else {
                // this.$message.success(
                //   '发送成功，即将跳转"我的"菜单查看最新处理状态'
                // )
                // setTimeout(() => {
                //   this.$router.push({ name: 'mine' })
                // }, 1000)
                this.messageDialogVisible = true
                this.clearFormData()
              }
            } else {
              this.$message.error('发送失败: ' + errorMsg || '')
            }
            this.showLoading = false
          } catch (err) {
            console.log(err)
            this.$message.error('发送失败: ' + err || '')
            this.showLoading = false
          }
        }
      })
    },
    //获取文件名
    getFileNameFromUrl(url) {
      // 创建一个URL对象
      const urlObj = new URL(url)

      // 获取路径部分
      const path = urlObj.pathname

      // 使用split方法以'/'为分隔符分割路径，并取最后一个元素作为文件名
      // 注意：这里假设URL格式是标准的，且文件名不包含'/'
      const fileName = path.split('/').pop()

      return fileName
    },
    clearFormData() {
      this.form = {
        id: '',
        department_id: '', //部门 id
        department: '', //需求创建人所属部门（隐形字段）
        subcategory: '', //子类 三级分类
        category: '', //分类  二级分类
        title: '',
        text: '',
        content: '',
        status: '',
        creator: {
          id: window.globalData?.user.userid || {},
          name: window.globalData?.user.name || {},
        },
        filelist: [],
        piclist: [],
      }

      this.fileList = []
      this.fileList2 = []
      this.updateStory = false
    },

    eventUploadSuccess(response) {
      try {
        let { code, msg, data } = response
        if (code == 0) {
          this.form.piclist.push(data)
          // this.$message.success('上传成功: ' + data);
          console.log('Picture list: ' + this.form.piclist)
        } else {
          this.$message.error(msg)
        }
      } catch (error) {
        this.$message.error('上传失败')
      }
    },

    eventUploadSuccessFile(response) {
      try {
        let { code, msg, data } = response
        if (code == 0) {
          this.form.filelist.push(data)
          // this.$message.success('上传成功: ' + data);
          console.log('filelist list: ' + this.form.filelist)
        } else {
          this.$message.error(msg)
        }
      } catch (error) {
        this.$message.error('上传失败')
      }
    },

    eventRemove(file) {
      console.log('Remove picture...', file)
      const i = this.form.piclist.findIndex((x) => x === file.url)
      this.form.piclist.splice(i, 1)
      console.log(this.form.piclist)
    },

    eventRemoveFile(file) {
      console.log('Remove File...', file)
      const i = this.form.filelist.findIndex((x) => x === file.url)
      this.form.filelist.splice(i, 1)
      console.log(this.form.filelist)
    },

    eventExceed() {
      const tip = '最多上传 ' + this.maxPics + ' 张图片!'
      this.$message.warning(tip)
    },
    eventExceedFile() {
      const tip = '最多上传 ' + this.maxPics + ' 个文件!'
      this.$message.warning(tip)
    },

    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'

      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.warning('上传图片只能是 JPG 或 PNG 格式!')
      }
      // if (!isLt2M) {
      //     this.$message.warning('上传图片大小不能超过 2MB!');
      // }
      // return (isJPG || isPNG) && isLt2M;
      return isJPG || isPNG
    },

    // canEdit() {
    //     let canEdit = this.form.status === ''

    //     console.log('canEdit: ' + this.form.status)

    //     return canEdit
    // },
  },
  async mounted() {
    this.clearFormData()

    let id = this.$route.params.id
    console.log('param:' + id)
    console.log('globalData', window.globalData)
    console.log('userid: ' + window.globalData?.user.userid)
    console.log('username: ' + window.globalData?.user.name)

    if (id) {
      let { data } = await get('api/opinions/get?id=' + id)

      if (data && data.code == 0) {
        let storyData = data.data
        //处理一下图片和文件 列表
        storyData.piclist = storyData.piclist || []
        storyData.filelist = storyData.filelist || []
        this.form = { ...storyData }
        this.form.department_id = storyData.workspaceId || ''
        this.form.id = storyData.id
        setTimeout(() => {
          this.form = {
            ...this.form,
            category: storyData.category,
            subcategory: storyData.type,
          }
        }, 1)
        this.form.title = storyData.title
        this.form.text = this.removeFromBrToEnd(storyData.text)
        //处理上传图片 和上传文件
        if (storyData.piclist) {
          this.form.piclist = Array.isArray(storyData.piclist)
            ? storyData.piclist
            : storyData.piclist.split('|')
          this.form.piclist.forEach((item) => {
            let picture = {}
            picture.name = this.getFileNameFromUrl(item)
            picture.url = item

            this.fileList.push(picture)
          })
        }
        if (storyData.filelist) {
          this.form.filelist = Array.isArray(storyData.filelist)
            ? storyData.filelist
            : storyData.filelist.split('|')
          this.form.filelist.forEach((item) => {
            let picture = {}
            picture.name = this.getFileNameFromUrl(item)
            picture.url = item

            this.fileList2.push(picture)
          })
        }

        console.log('eidt', data, this.form)

        this.updateStory = true
      }
    }
  },
}
</script>

<style>
.story {
  padding: 40px;
}

.styleShow .el-upload--picture-card {
  width: 110px;
  height: 110px;
  line-height: 110px;
}

.styleHide .el-upload--picture-card {
  display: none;
}
</style>