var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"story"},[_c('el-alert',{staticStyle:{"margin-left":"80px","margin-bottom":"10px","width":"560px"},attrs:{"title":"如您有多个建议或问题，请分别单独提交，便于管理和反馈。","type":"warning"}}),_c('el-form',{ref:"form",staticStyle:{"width":"640px"},attrs:{"model":_vm.form,"rules":_vm.rules,"disabled":!_vm.isLogin,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"建议事项","prop":"department_id"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择建议事项"},model:{value:(_vm.form.department_id),callback:function ($$v) {_vm.$set(_vm.form, "department_id", $$v)},expression:"form.department_id"}},_vm._l((_vm.departmentDic),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"分类","prop":"category"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"选择业务系统，如果不确定请选‘其他’"},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}},_vm._l((_vm.categoryDic),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"子类","prop":"subcategory"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.form.department_id && _vm.subcategoryDic.length == 0,"placeholder":_vm.form.department_id && _vm.subcategoryDic.length == 0
            ? ''
            : '请选择子类，如果不确定请选‘其他'},on:{"change":_vm.subcategoryChange},model:{value:(_vm.form.subcategory),callback:function ($$v) {_vm.$set(_vm.form, "subcategory", $$v)},expression:"form.subcategory"}},_vm._l((_vm.subcategoryDic),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"标题","prop":"title"}},[_c('el-input',{attrs:{"placeholder":"简单概括您的建议(不超过30个字)","maxlength":"30","show-word-limit":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"描述","prop":"text"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 10, maxRows: 15 },"maxlength":"2000","show-word-limit":"","placeholder":"详细说说您的想法或者遇到的问题(2000个字以内)"},model:{value:(_vm.form.text),callback:function ($$v) {_vm.$set(_vm.form, "text", $$v)},expression:"form.text"}})],1),_c('el-form-item',{attrs:{"label":"上传图片"}},[_c('el-upload',{class:{
          styleShow: _vm.form.piclist.length === 0,
          styleHide: _vm.form.piclist.length === _vm.maxPics,
        },attrs:{"list-type":"picture-card","multiple":true,"limit":this.maxPics,"auto-upload":true,"file-list":_vm.fileList,"action":"api/common/upload","before-upload":_vm.beforeUpload,"on-success":_vm.eventUploadSuccess,"on-remove":_vm.eventRemove,"on-exceed":_vm.eventExceed,"name":"file"}},[_c('i',{staticClass:"el-icon-plus",attrs:{"slot":"default"},slot:"default"}),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 最多上传 "+_vm._s(_vm.maxPics)+" 张图片，支持JPG、PNG格式 ")])])],1),_c('el-form-item',{attrs:{"label":"上传文件"}},[_c('el-upload',{class:{
          styleShow: _vm.form.filelist.length === 0,
          styleHide: _vm.form.filelist.length === _vm.maxPics,
        },attrs:{"multiple":true,"limit":this.maxPics,"auto-upload":true,"file-list":_vm.fileList2,"action":"api/common/upload","on-success":_vm.eventUploadSuccessFile,"on-remove":_vm.eventRemoveFile,"on-exceed":_vm.eventExceedFile,"name":"file"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 最多上传 "+_vm._s(_vm.maxPics)+" 个文件 ")])],1)],1),_c('el-form-item',[_c('el-button',{staticStyle:{"margin-left":"140px","width":"200px"},attrs:{"type":"primary","loading":_vm.showLoading},on:{"click":_vm.onSubmit}},[_vm._v("提交")])],1)],1),_c('el-dialog',{attrs:{"title":"感谢您的支持","visible":_vm.messageDialogVisible,"width":"40%","center":""},on:{"update:visible":function($event){_vm.messageDialogVisible=$event}}},[_c('span',[_vm._v("您的建议/需求已收到，因第三方系统问题，数据同步会有延迟，可能稍后才能在”我的“列表中看到，无需重新提交，感谢您的支持！")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.closeDialog}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }