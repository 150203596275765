<template>
    <div class="app">
        <div class="tdesign-demo-item--layout" style="height:100%">
            <t-layout style="height:100%">
                <t-header style="border-bottom: 1px solid #eee">
                    <t-head-menu value="item1" height="120px">
                        <h1 class="logo" slot="logo" style="margin-left:25px;">
                            <img src="./assets/mbkj-logo.png" style="width:160px;height:32px" />
                        </h1>
                        <template #operations>
                            <div style="padding-right:30px;">
                                <div style="display:flex;align-items: center;" v-if="user.userid">
                                    <!-- <img :src="user.avatar" style="width:36px;height:36px;border-radius:3px" /> -->
                                    <span style="margin-left:10px;">{{ user.name }}</span>
                                </div>
                                <template v-else>
                                    <div>暂未登录</div>
                                </template>
                            </div>
                        </template>
                    </t-head-menu>
                </t-header>
                <t-layout style="height:100%" v-if="isMobile() == false">
                    <t-aside>
                        <t-menu v-model="curMenu" theme="light" style="margin-right: 50px" height="550px"
                            @change="eventMenuChange">
                            <t-menu-item value="auth" v-if="run_locally">
                                <t-icon slot="icon" name="user-circle" />身份验证
                            </t-menu-item>
                            <t-menu-item value="story">
                                <t-icon slot="icon" name="user-talk" />发言
                            </t-menu-item>
                            <t-menu-item value="mine">
                                <t-icon slot="icon" name="user" />我的
                            </t-menu-item>
                            <t-menu-item value="all/1">
                                <t-icon slot="icon" name="usergroup" />我的部门
                            </t-menu-item>
                            <t-menu-item value="all/2">
                                <t-icon slot="icon" name="usergroup" />所有部门
                            </t-menu-item>
                        </t-menu>
                    </t-aside>
                    <t-layout style="border-left: 1px solid #eee;background: #fff;">
                        <t-content>
                            <div>
                                <router-view></router-view>
                            </div>
                        </t-content>
                        <t-footer>Copyright @ 2018-{{ new Date().getFullYear() }} 美保科技. All Rights Reserved
                        </t-footer>
                    </t-layout>
                </t-layout>
                <t-layout v-if="isMobile() == true">
                    <div>手机版正在开发中，请使用PC版提交需求。</div>
                </t-layout>
            </t-layout>
        </div>
    </div>
</template>

<script>


export default {
    data() {
        return {
            user: window.globalData?.user || {},
            run_locally: window.globalData?.config.run_locally || false,
            curMenu: "story"
        }
    },
    methods: {
        isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            console.log("mobile flag: " + flag)
            return flag != null;
        },

        eventMenuChange(hash) {
            console.log('eventMenuChange: ' + hash)
            this.$router.push(`/${hash}`)
        },

        handler: function () {
            // let curPath = this.$router.history.current.fullPath.replace("/", "")
            let fullPath = this.$router.history.current.fullPath
            console.log('fullPath')
            if (fullPath) {
                let curPath = fullPath
                if (curPath) {
                    console.log("Path: " + curPath)
                    this.curMenu = curPath.substr(1,curPath.length)
                }
            }
        }
    },
    watch: {
        $route: {
            handler: function () {
                console.log("router handler...")
                this.handler()
            },
            deep: true
        }
    },
    created() {
        this.$bus.on("edit", res => {
            console.log("Edit: " + res)
            this.curMenu = "story"
            this.$router.push(`/${this.curMenu}/` + res)
        });
    },
    mounted() {
        if (this.isMobile()) {
            console.log("手机端");
        } else {
            console.log("pc端");
        }
    },
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    background: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.app {

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

.wrap {
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
    align-items: center;
    box-sizing: content-box;
    background: #fff;
}

.navigation {
    border: 0 none !important;
}

.logo {
    display: flex;
    align-items: center;
    font-weight: 600;
}

.logo img {
    width: 32px;
}

.logo .title {
    font-family: Avenir;
    color: #333;
    font-weight: 500;
    font-size: 20px;
    /* color:rgba(0,0,255,0.4); */
    letter-spacing: -0.5px;
    padding-left: 5px;
}

.header-right {}

.header-profile {
    display: flex;
    align-items: center;
}

.header-profile-avatar {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 3px;
}

.qr_login {
    margin: 80px auto;
}

.left-menu {
    min-height: 100%;
}

.content-body {
    min-height: 680px;
}

.block {
    padding: 40px;
    border-radius: 10px;
    background: #fff;
    min-height: 680px;
    overflow: auto;
}

.block-header {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
}

.el-main {
    background: #fff;
}

.el-tree-node {
    margin: 3px 0;
}

.el-tree-node__content {
    height: 28px;
}

.el-textarea__inner {
    font-family: Arial, Helvetica, sans-serif;
}
</style>
